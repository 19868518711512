body {
  margin: 0;
  font-family: 'Zen', 'Zelda', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: 'Mundial Regular';
  src: local('Mundial Regular'), url(./fonts/Mundial-Regular.woff2) format('woff');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Grid {
  width: 40rem;
  max-width: 100%;
  height: 30rem;
  overflow-y: auto;
  margin-bottom: 2rem;
}

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	line-height: 1.15;
	box-sizing: border-box;
	scroll-behavior: smooth;
}
*, *::before, *::after {
	box-sizing: inherit;
}
body {
	margin: 0;
	padding: 0;
	font-family: 'Mundial Regular', sans-serif;
	color: #fff;
	background: linear-gradient(180deg, #120b1d, #140d2e);
	background-repeat:no-repeat;
	height:100%;
}
p{
	    font-weight: 300;
    line-height: 130%;
}
.container{
	width:100%;
	max-width:1000px;
	margin:auto;
}
.logo img{
	width:250px;
	margin:auto;
	display:block;
	margin-top:2em;
}
#hero h1{
	width: 77%;
    text-align: center;
    font-size: 3rem;
    margin: auto;
    margin: 1em auto;
	color: #8269ff;
}
#hero .asset{
	display: flex;
    margin: 0 auto 1em;
    width: 400px;
    height: 400px;
    border-radius: 10px;
}
#hero .asset img{
	width:100%;
}

#hero .social-links{
	width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#hero .social-links a{
	width: 45px;
    height: 45px;
    border: 4px solid #8269ff;
    display: block;
    padding: 4px;
    border-radius: 10px;
    background: #8269ff;
    margin: 0 10px;
}

#hero .social-links a img{
	width:100%;
}

.cta a{
	    display: block;
    margin: 1em auto;
    width: max-content;
    background: #00d0b6;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    line-height: 1;
    border-radius: 10px;
    min-width: 164px;
    text-align: center;
    font-weight: 600;
    color: #120b21;
}

.bg{
	    background: #190f2f;
    padding: 2em;
    border-radius: 20px;
}

#aboutus{
	margin: 4em 0;
}

#aboutus .bg{
	  display:flex;
	justify-content: space-between;
	align-items:center;
}

h2{
	display: flex;
    align-items:center;
	font-size: 2rem;
    letter-spacing: 1px;
	color:#8269ff;
}

h2 img{
	    width: 60px;
    margin-right: 10px;
}

.half{
	width:48%;
}

.inner-media{
	width: 100%;
    background: #231640;
    border-radius: 15px;
}

.inner-media img{
	width:100%;
}

#roadmap{
	    margin-bottom: 4em;
}

#team{
	margin-top:4em;
}

.accordion {
      background-color: #231640;
    color: #fff;
    cursor: pointer;
    padding: 18px;
    display: block;
    margin: auto;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 20px;
    text-align: left;
    max-width: 100%;
    width: 100%;
    border-radius: 11px;
    margin-bottom: 10px;
	position:relative;
	    font-family: 'Mundial Regular', sans-serif;
}

.accordion::after{
	content:'';
	background:url('https://solblockparty.com/wp-content/uploads/2022/02/plus.png');
	    background-size: 30px;
    position: absolute;
    top: 15px;
    right: 19px;
    width: 30px;
    height: 30px;
	
}

.active::after{
	content:'';
	background:url('https://solblockparty.com/wp-content/uploads/2022/02/minus.png');
	    background-size: 30px;
    position: absolute;
    top: 15px;
    right: 19px;
    width: 30px;
    height: 30px;
	
}

.active, .accordion:hover {
  background-color: #231640;
}

.panel {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-radius: 10px;
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel .text, .panel .graphic{
	width:48%;
	    padding: 2em 0;
}

.panel .graphic img{
	width:100%;
}
.artworkby{
	text-align: center;
}

.panel .text h3{
	font-size:34px; 
	display:flex;
	align-items:center;
}

.panel .text h3 span{
    font-size: 18px;
    font-weight: 300;
	color:#fff;
	margin-left:5px;
}

.purple-text{
	color:#8269ff;
}

.blue-text{
	color:#00d0b6;
}

#faq .panel p{
	padding:2em 0;
}

.team-member{
	width: 24%;
    background: #231640;
    border-radius: 10px;
    padding: 1em;
	margin-bottom:2em;
}

.team-member h4{
	    font-size: 14px;
	color: #01d2b7;
	height:21px;
}

.team-member .profile img{
	width:100%;
	border-radius:5px;
}

.team-member .minp{
	    min-height: 70px;
}

.team-member a img{
	width:22px;
	    margin-right: 5px;
}

.team-member h3 a{
	text-decoration:none;
	 color: #8269ff;
}

.team-member h3{
	    font-size: 18px;
        color: #8269ff;
}

#team .bg{
	    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#team h2{
	width:100%;
}

.mobile-img{
		display:none;
	}

footer{
	    text-align: center;
    padding: 4em 0;
}
#team h2{
	align-items:end;
	margin-top:0;
}

#team h2 span{
	       padding-bottom: 5px;
}



#roadmap .container img{
	    width: 100%;
	 border-radius: 20px;
	border-top-left-radius:0;
	border-top-right-radius:0;
}

.mmap{
		display:none;
	}
#roadmap h2 .peace {
    width: 60px;
}

#roadmap h2{
	margin-bottom:0;
}

#roadmap  .bg{
	background:#120b1d;
	border-bottom-left-radius:0;
	border-bottom-right-radius:0;
}

#gallery{
	margin-bottom:4em;
}

.slick-slide img {
    display: block;
    width: 98%;
}

.slick-prev:before, .slick-next:before{
	font-size:60px;
	opacity:1;
}

.slick-prev{
	left:2%;
	z-index:9999;
}
.dmap{width:100%;}
.slick-next{right:5%;}

@media(max-width:800px){
	.container {
   		 width: 95%;
	}
	.half, .panel .text, .panel .graphic {
    	width: 100%;
	}
	#aboutus .bg, .panel{
		flex-wrap:wrap;
	}
	.panel .graphic img {
   		 width: 289px;
    	margin: auto;
		display: block;
	}
	.desk-img{
		display:none;
	}
	.mobile-img{
		display:block;
	}
}

@media(max-width:600px){
	#hero h1 {
		width: 100%;
		    font-size: 2rem;
	}
	#hero .asset{
		width:300px;
		height:300px;
	}
	#hero .social-links {
		width: 100%;
	}
	h2{
		    font-size: 26px;
	}
	.bg{
		padding:1.5em;
	}
	.accordion{
		font-size:15px;
	}
	.team-member {
		width: 100%;
	}
	#faq .panel p{
		padding:0;
	}
	#roadmap .container img{
		width:100%;
	}
	.accordion{
		padding-right:65px;
	}
	.dmap{
		display:none;
	}
	.mmap{
		display:block;
		width:100%;
	}
	#roadmap h2 .peace{
		width: 60px;
	}
	.team-member h4{
		height:auto;
	}
	.team-member p{
		min-height:auto;
	}
	#team h2 {
		align-items: flex-end !important;
	}
}
