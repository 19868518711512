
.App {
  text-align: center;
  /*background-image: url(./img/background.png);*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  color: whitesmoke;
  background: linear-gradient(180deg, #120b1d, #140d2e);
	background-repeat:no-repeat;
}

.App-logo {
  max-width: 70vw;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  padding: 10vh 0 0 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
}

.App-header-content {
  background-color: transparent;
  align-content: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  width: 80vw;
  max-width:600px;
  font-size: calc(10px + 1vmin);
}

.App-link {
  color: gray;
  font-size: calc(6px + 2vmin);
}

.App-progressBar {
  width: 600;
  text-align: right;
  align-self: stretch;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: auto;
  min-width: 80vw;
}

.react-tabs__tab-list {
  border-bottom: none;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  color: #8269ff;
  border: 10px solid transparent;
  background-color: transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 20px;
  margin: 0px 20px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: transparent;
  color: white;
  border-radius: 0px 0px 0 0;
}

.react-tabs__tab--disabled {
  color: gray;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
  border-bottom: transparent;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
  width: 100%;
  text-align: center;
  align-items: center;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.App-image-bar {
  font-family: 'Courier New', Courier, monospace;
}

.App-god-button {
    display: block;
    margin: 1em auto;
    width: max-content;
    background: #00d0b6;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 20px;
    line-height: 1;
    border-radius: 10px;
    min-width: 164px;
    text-align: center;
    font-weight: 600;
    color: #120b21;
}
.App-god-button-trigger {
  
  color: linear-gradient(135deg,rgb(122,220,180),rgb(0,208,130));
  margin: 20;
  background-color: gray;
}

.App-normal-button {
  display: block;
  margin: 1em auto;
  border: none;
  color: black;
  font-size: xx-large;
  padding: 10px;
  margin: 2px;
  line-height: 1;
  background-color: #8269ff;
  cursor: pointer;
  font-family:'Mundial Regular', sans-serif;
  border-radius: 9px;
}
.App-normal-button-trigger {
  border: none;
  color: black;
  font-size: xx-large;
  padding: 10px;
  margin: 2px;
  background-color: #140d2e;
  cursor: pointer;
  font-family:'Mundial Regular', sans-serif;
  border-radius: 9px;
}



.App-god-button:not([disabled]):hover {
  background-color: rgb(65,88,208);
}

.Item {
  display: grid;
  place-items: center;

  --padding: 1rem;
  padding: var(--padding);
  height: calc(100% - var(--padding) * 2);

  background-color: hsl(219, 100%, 95%);
  border-radius: 4px;

  cursor: pointer;
}


.Grid {
  width: 40rem;
  max-width: 100%;
  height: 30rem;
  overflow-y: auto;
  margin-bottom: 2rem;
}